import React, { useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router-dom';
import { apiGet } from '../../../../api';
import { useFetch } from '../../../../hooks/useFetch';
import { downloadBase64File } from '../../../../utils/downloadBase64';
import { Breadcrumbs } from '../../../components';
import { Download } from '../../../icons';
import './index.scss';

const TemplateSummaryReportElementsDownload = () => {
  const { id, idTemplateSummaryReport } = useParams();
  const [pageModalDownload, setPageModalDownload] = useState(1);
  const [sending, setSending] = useState(false);

  const { response: getReportDataForDownload } = useFetch({
    url: `/data-report?page=${pageModalDownload}&limit=10&template_id=${id}&data_report_template_id=${idTemplateSummaryReport}`,
  });

  // const { response } = useFetch({ url: `/data-report/get-search-attributes` })
  // console.log(response)

  const downloadFile = id => {
    if (!sending) {
      apiGet({
        url: `/data-report/view?id=${id}`,
      })
        .then(res => {
          if (res.error) {
            console.error('Скачивание элемента шаблона сводного отчета');
            return;
          }

          downloadBase64File(res.data.asBase64, `${res.data.fileName}`);
        })
        .finally(() => setSending(false));
    }
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
    {
      name: 'Шаблоны сводных отчетов',
      link: `/template-summary-report/${id}`,
    },
    {
      name: 'Элементы шаблона сводного отчета',
      link: `/template-summary-report-elements/${id}/${idTemplateSummaryReport}`,
    },
    {
      name: 'Элементы шаблона сводного отчета готовые к скачиванию',
      link: `/template-summary-report-elements-download/${id}/${idTemplateSummaryReport}`,
    },
  ];

  return (
    <>
      <div className="template-summary-report-elements">
        <Breadcrumbs linkRoute={linkRoute} />
        <div className="card">
          <div className="card-header ">
            <div className="row jc-between">
              <div className="col-auto mb-2">
                <span>Список элементов сводных отчетов по данным</span>
              </div>
            </div>
          </div>
          <div className="card-content">
            <div className="scroll-x">
              <table className="mt-4 low-table">
                <table className="low-table">
                  <thead>
                    <tr>
                      <th>Название шаблона</th>
                      <th>Время отчета</th>
                      <th>Тип организации</th>
                      <th>Статус</th>
                      <th className="settings"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getReportDataForDownload?.models.map(item => (
                      <tr key={item.id}>
                        <td>{item?.dataReportTemplate?.template?.name}</td>
                        <td>{item?.reportTime}</td>
                        <td>{item?.forDeletedOrganizations ? 'Исключенные' : 'Действующие'}</td>
                        <td>{item?.completedAt ? 'Готово к выгрузке' : 'Выполняется обработка'}</td>
                        <td>
                          <div className="col-auto">
                            {item?.completedAt && (
                              <button
                                className="action-btn btn btn-light"
                                onClick={() => downloadFile(item.id)}
                                title="Скачать отчет"
                              >
                                <Download />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </table>
            </div>

            <div className="mt-2 ta-center">
              <ReactPaginate
                pageCount={getReportDataForDownload?.lastPage == 1 ? 0 : getReportDataForDownload?.lastPage}
                pageRangeDisplayed={5}
                marginPagesDisplayed={3}
                onPageChange={e => setPageModalDownload(e.selected + 1)}
                activeClassName={'active'}
                containerClassName={'pagination d-inline-flex ta-center'}
                pageLinkClassName={'link'}
                pageClassName={'link'}
                previousLabel={false}
                nextLabel={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateSummaryReportElementsDownload;
