import { useEffect, useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactPaginate from 'react-paginate';
import { Link, useHistory, useParams } from 'react-router-dom';
import SelectCustom from 'react-select';
import { apiGet, apiPost } from '../../../../api';
import updateUrl from '../../../../hooks/updateUrl';
import useDebounce from '../../../../hooks/useDebounce';
import { useFetch } from '../../../../hooks/useFetch';
import useUrl from '../../../../hooks/useUrl';
import { downloadBase64File } from '../../../../utils/downloadBase64';
import { Breadcrumbs, Input, Modal, Pagination } from '../../../components';
import FirefoxDateTime from '../../../components/FireFoxDateTime/FirefoxDateTime';
import { Download, Gear, TrashCan } from '../../../icons';
import './index.scss';

const TemplateSummaryReportElements = () => {
  const { id, idTemplateSummaryReport } = useParams();
  const history = useHistory();
  const { page } = useUrl();
  const [modalDownload, setModalDownload] = useState(false);
  const [sending, setSending] = useState(false);
  const [errObj, setErrObj] = useState({});
  const [pageModal, setPageModal] = useState(1);
  const [pageModalDownload, setPageModalDownload] = useState(1);
  const [modalCreate, setModalCreate] = useState(false);
  const [modalDownloadAccess, setModalDownloadAccess] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [elementTextEdit, setElementTextEdit] = useState('');
  const [searchEmelentName, setSearchElementName] = useState('');
  const [editNameTemplateSummaryElementReport, setEditNameTemplateSummaryElementReport] = useState('');
  const [elementIdEdit, setElementIdEdit] = useState('');
  const [createNameTemplateSummaryElementReport, setCreateNameTemplateSummaryElementReport] = useState('');
  const [createElementText, setCreateElementText] = useState('');
  const [reportIds, setReportIds] = useState([]);
  const [reportDataValue, setReportDateValue] = useState('');
  const [reportDate, setReportDate] = useState('');
  const [afterElementId, setAfterElementId] = useState('');
  const [searchAfterElementId, setSearchAfterElementId] = useState('');
  const [createPositionElement, setCreatePositionElement] = useState('end');
  const [deletedOrganizationsCheckbox, setDeletedOrganizationsCheckbox] = useState(false);

  const { response: afterElementsData } = useFetch({
    url: `/data-report-element?limit=10&data_report_template_id=${idTemplateSummaryReport}&name=${
      searchAfterElementId || ''
    }`,
  });

  const { response: getReportData } = useFetch({
    url: `/report?page=${pageModal}&limit=10&template_id=${id}`,
  });

  const { response: getReportDataForDownload, fetchData: updateGetReportFromDownload } = useFetch({
    url: `/data-report?page=${pageModalDownload}&limit=10&template_id=${id}`,
  });

  const { response: treeReport } = useFetch({
    url: `/report-template/reference?id=${id}`,
  });

  const debouncedTriggerName = useDebounce(createElementText);
  const url = debouncedTriggerName.length
    ? `/report-element?instance_id=${treeReport?.id}&name=${debouncedTriggerName}&limit=10`
    : null;

  const { response: triggerSearchList, setResponse: setTriggerSearchList } = useFetch({ url });

  const editDebouncedTriggerName = useDebounce(elementTextEdit);
  const urlEdit = editDebouncedTriggerName.length
    ? `/report-element?instance_id=${treeReport?.id}&name=${editDebouncedTriggerName}&limit=10`
    : null;

  const { response: triggerSearchListEdit, setResponse: setTriggerSearchListEdit } = useFetch({ url: urlEdit });

  const reformatDate = dateStr => {
    return dateStr.replace(/(\d+)-(\d+)-(\d+)T(\d+):(\d+)/, '$3.$2.$1 $4:$5');
  };

  const { response, fetchData } = useFetch({
    url: `/data-report-element?page=${page}&limit=10&data_report_template_id=${idTemplateSummaryReport}&name=${
      searchEmelentName || ''
    }`,
  });

  const toggleCheckbox = id => {
    let arr = [...reportIds];

    if (reportIds.includes(id)) {
      arr = arr.filter(i => i !== id);
    } else arr.push(id);

    setReportIds(arr);
  };

  const createTemplateSummaryReportElement = () => {
    const postData = {
      name: createNameTemplateSummaryElementReport,
      element_name: createElementText,
    };
    if (createPositionElement == 'afterElementId') {
      postData.afterElementId = afterElementId.value;
    }
    if (createPositionElement == 'beforeElementId') {
      postData.beforeElementId = afterElementId.value;
    }
    if (!sending) {
      apiPost({
        url: `/data-report-element/create?data_report_template_id=${idTemplateSummaryReport}`,
        postData: postData,
      })
        .then(res => {
          if (res?.status === 406) {
            setErrObj(res.data);
            return;
          }
          if (res.error) {
            console.error('Создание элемента шаблона сводного отчета по данным');
            return;
          }
          setCreatePositionElement('end');
          setAfterElementId();
          setErrObj({});
          setCreateNameTemplateSummaryElementReport('');
          setCreateElementText('');
          fetchData();
          setModalCreate(false);
        })
        .finally(() => setSending(false));
    }
  };

  const editTemplateSummaryReportElement = () => {
    const postData = {
      name: editNameTemplateSummaryElementReport,
      element_name: elementTextEdit,
    };
    if (createPositionElement == 'afterElementId') {
      postData.afterElementId = afterElementId.value;
    }
    if (createPositionElement == 'beforeElementId') {
      postData.beforeElementId = afterElementId.value;
    }
    if (!sending) {
      apiPost({
        url: `/data-report-element/update?id=${elementIdEdit}`,
        postData: postData,
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          }
          if (res.error) {
            console.error('Изменение элемента шаблона сводного отчета по данным');
            return;
          }
          setCreatePositionElement('end');
          setAfterElementId();
          setErrObj({});
          setCreateNameTemplateSummaryElementReport('');
          setElementTextEdit('');
          fetchData();
          setModalEdit(false);
        })
        .finally(() => setSending(false));
    }
  };
  const emptyCheckedIds = () => {
    confirmAlert({
      message: 'Пожалуйста выберите отчет(ы)',
      buttons: [
        {
          label: 'Ок',
          onClick: e => e,
        },
      ],
    });
  };

  const alertFromSetDownload = () => {
    confirmAlert({
      message: 'Отчет поставлен на выгрузку, подождите пока его обработает сервер',
      buttons: [
        {
          label: 'Ок',
          onClick: () => history.push(`/template-summary-report-elements-download/${id}/${idTemplateSummaryReport}`),
        },
      ],
    });
  };

  const templateSummaryReportDownload = () => {
    if (!reportIds.length) {
      emptyCheckedIds();
      return;
    }
    let arrStr = reportIds.join();
    if (!sending) {
      apiPost({
        url: `/data-report/create?data_report_template_id=${idTemplateSummaryReport}`,
        postData: {
          reportIds: arrStr,
          report_time: reportDate,
          for_deleted_organizations: deletedOrganizationsCheckbox,
        },
      })
        .then(res => {
          if (res.status === 406) {
            setErrObj(res.data);
            return;
          }
          if (res.error) {
            console.error('Выгрузка сводного отчета по данным');
            return;
          }
          alertFromSetDownload();
          setErrObj({});
          setReportDate('');
          setReportDateValue('');
          setReportIds([]);
          setModalDownload(false);
          setDeletedOrganizationsCheckbox(false);
          updateGetReportFromDownload();
        })
        .finally(() => setSending(false));
    }
  };

  const peasteTriggerElement = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-element/copy?id=${id}&copyMode=This` })
        .then(res => {
          if (res.error) {
            console.error('Вставка триггера триггера');
            return;
          }

          setCreateElementText(res.data);
        })
        .finally(() => setSending(false));
    }
  };

  const peasteTriggerElementEdit = id => {
    if (!sending) {
      setSending(true);

      apiGet({ url: `/report-element/copy?id=${id}&copyMode=This` })
        .then(res => {
          if (res.error) {
            console.error('Вставка триггера триггера');
            return;
          }

          setElementTextEdit(res.data);
        })
        .finally(() => setSending(false));
    }
  };

  const getSearchItem = id => {
    peasteTriggerElement(id);
    setTriggerSearchList([]);
  };

  const getSearchItemEdit = id => {
    peasteTriggerElementEdit(id);
    setTriggerSearchListEdit([]);
  };

  const deleteTemplateSummaryReportElent = id => {
    if (!sending) {
      apiGet({
        url: `/data-report-element/delete?id=${id}`,
      })
        .then(res => {
          if (res.error) {
            console.error('Удаление элемента шаблона сводного отчета по данным');
            return;
          }
          fetchData();
        })
        .finally(() => setSending(false));
    }
  };

  const downloadFile = id => {
    if (!sending) {
      apiGet({
        url: `/data-report/view?id=${id}`,
      })
        .then(res => {
          if (res.error) {
            console.error('Скачивание элемента шаблона сводного отчета по данным');
            return;
          }
          downloadBase64File(res.data.asBase64, `${res.data.fileName}.xlsx`);
        })
        .finally(() => setSending(false));
    }
  };

  const alerDeleteTemplateSummaryReportElement = id => {
    confirmAlert({
      title: 'Удаление',
      message: 'Вы точно хотите удалить элемент шаблона сводного отчета по данным?',
      buttons: [
        {
          label: 'Да',
          onClick: () => deleteTemplateSummaryReportElent(id),
        },
        {
          label: 'Нет',
        },
      ],
    });
  };

  const linkRoute = [
    {
      name: 'Шаблоны отчетов',
      link: '/reports-template',
    },
    {
      name: 'Просмотр шаблона',
      link: `/view-report/${id}`,
    },
    {
      name: 'Шаблоны сводных отчетов по данным',
      link: `/template-summary-report/${id}`,
    },
    {
      name: 'Элементы шаблона сводного отчета по данным',
      link: `/template-summary-report-elements/${id}/${idTemplateSummaryReport}`,
    },
  ];

  const openEditModal = item => {
    setEditNameTemplateSummaryElementReport(item.name);
    setElementIdEdit(item.id);
    setElementTextEdit(item?.element_name);
    setModalEdit(true);
  };

  const funcUpdateDate = e => {
    setReportDateValue(e);
    setReportDate(reformatDate(e));
  };

  const funcUpdateDateFirefox = e => {
    setReportDateValue(e);
    setReportDate(e);
  };

  const functionSearch = e => {
    setSearchElementName(e);
    history.push(updateUrl({ key: 'page', value: '1' }));
  };

  useEffect(() => {
    if (!debouncedTriggerName.length) {
      setTriggerSearchList([]);
    }
  }, [debouncedTriggerName]);

  useEffect(() => {
    if (!editDebouncedTriggerName.length) {
      setTriggerSearchListEdit([]);
    }
  }, [editDebouncedTriggerName]);

  return (
    <>
      <div className="template-summary-report-elements">
        <Breadcrumbs linkRoute={linkRoute} />
        <div className="card">
          <div className="card-header ">
            <div className="row jc-between">
              <div className="col-auto mb-2">
                <span>Список элементов сводных отчетов по данным</span>
              </div>

              <div className="col-auto">
                <button className="btn btn-primary" onClick={() => setModalCreate(true)}>
                  Создание элемента шаблона сводного отчета по данным
                </button>
              </div>
            </div>
          </div>
          <div className="card-content">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-4 col-xl-3">
                <Input fieldClasses="mb-0" placeholder="Поиск..." onChange={e => functionSearch(e)} />
              </div>
            </div>
            <div className="scroll-x">
              <table className="mt-4 low-table">
                <thead>
                  <tr>
                    <th>Название</th>
                    <th className="settings"></th>
                  </tr>
                </thead>
                <tbody>
                  {response?.models.map(item => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>
                        <div className="row row-sm jc-end">
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-warning"
                              onClick={() => openEditModal(item)}
                              title="Изменить"
                            >
                              <Gear />
                            </button>
                          </div>
                          <div className="col-auto">
                            <button
                              className="action-btn btn btn-danger"
                              onClick={() => alerDeleteTemplateSummaryReportElement(item.id)}
                              title="Удаление"
                            >
                              <TrashCan />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="mt-2 ta-center">
              <Pagination lastPage={response?.lastPage} page={page} />
            </div>
            {response?.models?.length > 0 && (
              <div className="row row-sm mt-4">
                <div className="col-auto">
                  <button onClick={() => setModalDownload(true)} className="btn btn-light">
                    Список сводных отчетов для выгрузки
                  </button>
                </div>
                <div className="col-auto">
                  <Link
                    to={`/template-summary-report-elements-download/${id}/${idTemplateSummaryReport}`}
                    onClick={() => setModalDownloadAccess(true)}
                    className="btn btn-primary"
                  >
                    Список сводных отчетов готовых к выгрузке
                  </Link>
                </div>
              </div>
            )}
          </div>
          <Modal
            fieldClasses="h-100"
            show={modalCreate}
            onClose={() => setModalCreate(false)}
            modalTitle="Создание элемента шаблона сводного отчета по данным"
            footer={
              <button className="btn btn-primary" onClick={() => createTemplateSummaryReportElement()}>
                Создать
              </button>
            }
          >
            <div className="row">
              <div className="col-12">
                <Input
                  label="Название элемента"
                  value={createNameTemplateSummaryElementReport}
                  onChange={setCreateNameTemplateSummaryElementReport}
                  error={errObj.name}
                />
              </div>
              <div className="col-12">
                <Input
                  fieldClasses="mb-0 pos"
                  label="Элемент"
                  value={createElementText}
                  onChange={setCreateElementText}
                  error={errObj.name}
                />

                {triggerSearchList && (
                  <div className="search-list">
                    {triggerSearchList.map(i => (
                      <div key={i.id} className="item cursor-pointer" onClick={() => getSearchItem(i.id)}>
                        {i.name} {i.value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label style={{ marginTop: '30px' }} className="form-field form-label size">
                  Расположение элемента
                </label>
                <label className="form-field form-label size pl-0">
                  <select
                    name="#"
                    onChange={e => setCreatePositionElement(e.target.value)}
                    className="form-input _select"
                    value={createPositionElement}
                  >
                    <option value="end">Расположить без учета порядка</option>
                    <option value="afterElementId">Расположить до элемента</option>
                    <option value="beforeElementId">Расположить после элемента</option>
                  </select>
                </label>
              </div>
              {createPositionElement !== 'end' && (
                <div className="col-12">
                  <label style={{ marginTop: '30px' }} className="form-field form-label size">
                    Очередность элемента
                  </label>
                  {afterElementsData && (
                    <SelectCustom
                      className="select-company mb-4 p-0"
                      classNamePrefix="select"
                      placeholder=""
                      options={afterElementsData?.models.map(i => ({
                        value: i.id,
                        label: i.name,
                      }))}
                      value={afterElementId}
                      onChange={setAfterElementId}
                      onInputChange={e => setSearchAfterElementId(e)}
                      noOptionsMessage={() => 'Нет вариантов..'}
                    />
                  )}
                </div>
              )}
            </div>
          </Modal>
          <Modal
            fieldClasses="h-100"
            show={modalEdit}
            onClose={() => setModalEdit(false)}
            modalTitle="Изменение элемента шаблона сводного отчета по данным"
            footer={
              <button className="btn btn-primary" onClick={() => editTemplateSummaryReportElement()}>
                Изменить
              </button>
            }
          >
            <div className="row">
              <div className="col-12">
                <Input
                  label="Название элемента"
                  value={editNameTemplateSummaryElementReport}
                  onChange={setEditNameTemplateSummaryElementReport}
                  error={errObj.name}
                />
              </div>
              <div className="col-12">
                <Input
                  fieldClasses="mb-0 pos"
                  label="Элемент"
                  value={elementTextEdit}
                  onChange={setElementTextEdit}
                  error={errObj.name}
                />
                {triggerSearchListEdit && (
                  <div className="search-list">
                    {triggerSearchListEdit.map(i => (
                      <div key={i.id} className="item cursor-pointer" onClick={() => getSearchItemEdit(i.id)}>
                        {i.name} {i.value}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="col-12">
                <label style={{ marginTop: '30px' }} className="form-field form-label size">
                  Расположение элемента
                </label>
                <label className="form-field form-label size pl-0">
                  <select
                    name="#"
                    onChange={e => setCreatePositionElement(e.target.value)}
                    className="form-input _select"
                    value={createPositionElement}
                  >
                    <option value="end">Расположить без учета порядка</option>
                    <option value="afterElementId">Расположить до элемента</option>
                    <option value="beforeElementId">Расположить после элемента</option>
                  </select>
                </label>
              </div>
              {createPositionElement !== 'end' && (
                <div className="col-12">
                  <label style={{ marginTop: '30px' }} className="form-field form-label size">
                    Очередность элемента
                  </label>
                  {afterElementsData && (
                    <SelectCustom
                      className="select-company mb-4 p-0"
                      classNamePrefix="select"
                      placeholder=""
                      options={afterElementsData?.models.map(i => ({
                        value: i.id,
                        label: i.name,
                      }))}
                      value={afterElementId}
                      onChange={setAfterElementId}
                      onInputChange={e => setSearchAfterElementId(e)}
                      noOptionsMessage={() => 'Нет вариантов..'}
                    />
                  )}
                </div>
              )}
            </div>
          </Modal>
          <Modal
            fieldClasses="h-100"
            show={modalDownload}
            onClose={() => setModalDownload(false)}
            modalTitle="Выгрузка сводного отчета"
            footer={
              <button className="btn btn-primary" onClick={() => templateSummaryReportDownload()}>
                Выгрузить
              </button>
            }
          >
            <div className="row items-center gap-3">
              <div className="col-4">
                {navigator.userAgent.includes('Firefox') ? (
                  <FirefoxDateTime
                    value={reportDataValue}
                    label="Время отчета"
                    onChange={e => funcUpdateDate(e)}
                    error={errObj.reportTime}
                  />
                ) : (
                  <Input
                    label="Время отчета"
                    type="datetime-local"
                    value={reportDataValue}
                    error={errObj.reportTime}
                    onChange={e => funcUpdateDateFirefox(e)}
                  />
                )}
              </div>
              <div className="col-4">
                <label className="inline-flex gap-2 items-center cursor-pointer w-fit">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={deletedOrganizationsCheckbox}
                    onChange={e => setDeletedOrganizationsCheckbox(e.target.checked)}
                  />

                  <span className="font-medium whitespace-nowrap">Только для исключенных организаций</span>
                </label>
              </div>
            </div>
            <div className="card-content p-0">
              <div className="scroll-x">
                <table className="low-table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Название шаблона</th>
                      <th>Период</th>
                      <th className="settings"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getReportData?.models.map(item => (
                      <tr key={item.id}>
                        <td>
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={reportIds.includes(item.id)}
                            onChange={() => toggleCheckbox(item.id)}
                          />
                        </td>
                        <td>{item?.template?.name}</td>
                        <td>{item?.period?.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-2 ta-center">
                <ReactPaginate
                  pageCount={getReportData?.lastPage == 1 ? 0 : getReportData?.lastPage}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={3}
                  onPageChange={e => setPageModal(e.selected + 1)}
                  activeClassName={'active'}
                  containerClassName={'pagination d-inline-flex ta-center'}
                  pageLinkClassName={'link'}
                  pageClassName={'link'}
                  previousLabel={false}
                  nextLabel={false}
                />
              </div>
            </div>
          </Modal>

          <Modal
            fieldClasses="h-100"
            show={modalDownloadAccess}
            onClose={() => setModalDownloadAccess(false)}
            modalTitle="Запросы для выгрузки сводного отчета"
          >
            <div className="card-content p-0">
              <div className="scroll-x">
                <table className="low-table">
                  <thead>
                    <tr>
                      <th>Название шаблона</th>
                      <th>Время отчета</th>
                      <th>Статус</th>
                      <th className="settings"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getReportDataForDownload?.models.map(item => (
                      <tr key={item.id}>
                        <td>{item?.dataReportTemplate?.template?.name}</td>
                        <td>{item?.reportTime}</td>
                        <td>{item?.completedAt ? 'Готово к выгрузке' : 'Выполняется обработка'}</td>
                        <td>
                          <div className="col-auto">
                            {item?.completedAt && (
                              <button
                                className="action-btn btn btn-light"
                                onClick={() => downloadFile(item.id)}
                                title="Скачать отчет"
                              >
                                <Download />
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="mt-2 ta-center">
                <ReactPaginate
                  pageCount={getReportDataForDownload?.lastPage == 1 ? 0 : getReportDataForDownload?.lastPage}
                  pageRangeDisplayed={5}
                  marginPagesDisplayed={3}
                  onPageChange={e => setPageModalDownload(e.selected + 1)}
                  activeClassName={'active'}
                  containerClassName={'pagination d-inline-flex ta-center'}
                  pageLinkClassName={'link'}
                  pageClassName={'link'}
                  previousLabel={false}
                  nextLabel={false}
                />
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default TemplateSummaryReportElements;
